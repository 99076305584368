import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import ApiConfig from "constants/ApiConfigs.const";
import {
  IAddWorkspaceUser,
  ICreateProjectPayload,
  ICreateWorkspaceRequest,
  IWorkspace,
  IWorkspaceState,
} from "interfaces/Workspace.interface";
import { useSelector } from "react-redux";
import httpService from "services/HttpService";
import { RootState } from "../store";
import { setErrorToast } from "./ToastSlice";
import { getActionNameForLoader, getApiRespObj } from "../../shared/helpers/store.helper";
import { PURGE } from "redux-persist";
import { LineItemCustomEmailPayload } from "../../interfaces/LineItemListing.interface";

const initialState: IWorkspaceState = {
  workspaceCreationResponse: null,
  workspaces: [],
  projects: [],
  hasPowerBIReport: false,
  selectedWorkspace: null,
  projectCreationResponse: null,
  lineItemsListingData: [],
  lineItemsListingFiltersData: null,
  powerBiDashboard: null,
};

// Async action example
export const createWorkspace = createAsyncThunk(
  getActionNameForLoader("workspace/createWorkspace"),
  async (newWorkspaceData: ICreateWorkspaceRequest, { rejectWithValue, dispatch }) => {
    try {
      const response = await httpService.post(ApiConfig.endpoints.create_workspace, newWorkspaceData);
      return response.data;
    } catch (error: any) {
      dispatch(setErrorToast({ message: error?.response?.data?.message }));
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getWorkspaces = createAsyncThunk(
  getActionNameForLoader("workspace/getWorkspaces"),
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await httpService.get(ApiConfig.endpoints.get_workspaces);
      return response.data.data;
    } catch (error: any) {
      dispatch(setErrorToast({ message: error?.response?.data?.message }));
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getWorkspaceProjects = createAsyncThunk(
  getActionNameForLoader("workspace/getWorkspaceProjects"),
  async (workspace: IWorkspace, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setSelectedWorkspace(workspace));
      const response = await httpService.get(`${ApiConfig.endpoints.get_workspace_projects}/${workspace.id}`);
      return response.data.data;
    } catch (error: any) {
      dispatch(setErrorToast({ message: error?.response?.data?.message }));
      return rejectWithValue(error?.response?.data);
    }
  }
);


export const createProject = createAsyncThunk(
  getActionNameForLoader("workspace/createProject"),
  async (payload: ICreateProjectPayload, { rejectWithValue, dispatch }) => {
    try {
      const response = await httpService.post(ApiConfig.endpoints.create_project, payload);
      return response.data;
    } catch (error: any) {
      dispatch(setErrorToast({ message: error?.response?.data?.message }));
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getPowerBiDashboard = createAsyncThunk(
  getActionNameForLoader("workspace/getPowerBiDashboard"),
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await httpService.post(ApiConfig.endpoints.get_powerbi_dashboard, payload);
      return response.data;
    } catch (error: any) {
      dispatch(setErrorToast({ message: error?.response?.data?.message }));
      return rejectWithValue(error?.response?.data);
    }
  }
);


export const getWorkspaceUsers = createAsyncThunk(
  getActionNameForLoader("workspace/getWorkspaceUsers"),
  async (workspace: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await httpService.get(`${ApiConfig.endpoints.get_workspace_users}/${workspace.id}`);
      return response.data.data;
    } catch (error: any) {
      dispatch(setErrorToast({ message: error?.response?.data?.message }));
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const addUserToWorkspace = createAsyncThunk(
  getActionNameForLoader("workspace/addUserToWorkspace"),
  async (payload: IAddWorkspaceUser, { rejectWithValue, dispatch }) => {
    try {
      const response = await httpService.patch(ApiConfig.endpoints.add_user_to_workspace, payload);
      return getApiRespObj(response.data);
    } catch (error: any) {
      dispatch(setErrorToast({ message: error?.response?.data?.message }));
      return rejectWithValue(getApiRespObj(error?.response?.data));
    }
  }
);

export const getWorkspaceDetails = createAsyncThunk(
  getActionNameForLoader("workspace/getWorkspaceDetails"),
  async (workspaceId: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await httpService.get(`${ApiConfig.endpoints.get_workspace_details}/${workspaceId}`);
      return getApiRespObj(response.data);
    } catch (error: any) {
      dispatch(setErrorToast({ message: error?.response?.data?.message }));
      return rejectWithValue(getApiRespObj(error?.response?.data));
    }
  }
);

// TODO: Move this to LineItemListingSlice
export const getWorkspaceLineItemsMilestones = createAsyncThunk(
  getActionNameForLoader("workspace/getWorkspaceLineItemsMilestones"),
  async (data: { workspaceId: string; query?: string }, { rejectWithValue, dispatch }) => {
    try {
      const response = await httpService.get(
        `${ApiConfig.endpoints.get_workspace_line_items_milestones}/${data.workspaceId}/get-line-items${
          data.query ? `?${data.query}` : ""
        }`
      );
      return getApiRespObj(response.data);
    } catch (error: any) {
      dispatch(setErrorToast({ message: error?.response?.data?.message }));
      return rejectWithValue(getApiRespObj(error?.response?.data));
    }
  }
);

export const sendLineItemCustomEmail = createAsyncThunk(
  getActionNameForLoader("workspace/sendLineItemCustomEmail"),
  async (emailData: LineItemCustomEmailPayload, { rejectWithValue, dispatch }) => {
    try {
      const response = await httpService.post(ApiConfig.endpoints.send_line_item_custom_email, emailData);
      return getApiRespObj(response.data);
    } catch (error: any) {
      dispatch(setErrorToast({ message: error?.response?.data?.message }));
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const downloadFiles = createAsyncThunk(
  getActionNameForLoader("workspace/downloadFiles"),
  async (milestoneIds: string[], { rejectWithValue, dispatch }) => {
    try {
      const response = await httpService.post(ApiConfig.endpoints.download_drawings, { milestoneIds });
      return getApiRespObj(response.data);
    } catch (error: any) {
      dispatch(setErrorToast({ message: error?.response?.data?.message }));
      return rejectWithValue(error?.response?.data);
    }
  }
);

const workspaceSlice = createSlice({
  name: "workspace",
  initialState,
  reducers: {
    setSelectedWorkspace(state, action: PayloadAction<IWorkspace | null>) {
      state.selectedWorkspace = action.payload;
      if (action.payload === null) state.projects = [];
    },
    resetLineItemListingsData(state) {
      state.lineItemsListingData = initialState.lineItemsListingData;
      state.lineItemsListingFiltersData = initialState.lineItemsListingFiltersData;
    },
  },
  extraReducers: (builder) => {
    // upon logout
    builder.addCase(PURGE, () => initialState);

    builder.addCase(getWorkspaces.fulfilled, (state, action) => {
      state.workspaces = action.payload;
    });

    builder.addCase(getPowerBiDashboard.fulfilled, (state, action: PayloadAction<any>) => {
      state.powerBiDashboard = action.payload;
    });
    builder.addCase(createWorkspace.fulfilled, (state, action) => {
      state.workspaceCreationResponse = action.payload;
    });

    builder.addCase(getWorkspaceProjects.fulfilled, (state, action) => {
      state.projects = action.payload.projects;
      state.hasPowerBIReport = action.payload.hasPowerBIReport;
    });

    builder.addCase(createProject.fulfilled, (state, action: PayloadAction<any>) => {
      state.projectCreationResponse = action.payload;
    });

    builder.addCase(getWorkspaceUsers.fulfilled, (state, action) => {
      if (state.selectedWorkspace) {
        state.selectedWorkspace = {
          ...state.selectedWorkspace,
          workspaceUsers: action.payload.workspaceUsers.map((user: any) => user.userId),
        };
      }
    });

    builder.addCase(getWorkspaceLineItemsMilestones.fulfilled, (state, action) => {
      state.lineItemsListingData = action.payload?.data?.lineItemsWithMilestones ?? initialState.lineItemsListingData;
      state.lineItemsListingFiltersData =
        action.payload?.data?.filterValues ?? initialState.lineItemsListingFiltersData;
    });
  },
});

export const { setSelectedWorkspace, resetLineItemListingsData } = workspaceSlice.actions;

export default workspaceSlice.reducer;

export const useWorkspaceSelector = () => useSelector((state: RootState) => state.workspace);
