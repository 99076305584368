import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  ICommentStats,
  ICreateLineItem,
  ILineItem,
  ILineItemCommentState,
  ILineItemsState,
  ILineItemWithMilestones,
  IPO,
  IPOSummary,
} from "../../interfaces/Workspace.interface";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { getActionNameForLoader, getApiRespObj } from "../../shared/helpers/store.helper";
import ApiConfig from "../../constants/ApiConfigs.const";
import httpService from "../../services/HttpService";
import { setErrorToast } from "./ToastSlice";
import { PURGE } from "redux-persist";
import {
  AddMilestoneComponentCommentPayload,
  ComponentsState,
  IComponent,
  ICreateComponent,
  ICreateMilestone,
  IMilestone,
  IMilestoneComment,
  IUpdateMilestone,
  UpdateMilestoneComponentPayload,
  UpdateMilestoneComponentsMoveStagePayload,
} from "../../interfaces/Milestone.interface";
import { DropdownOptionType } from "../../interfaces/Form.interface";
import { EDIT_COMPO_TABS, PO_LISTING_VIEW_OPTIONS_VALUE } from "../../templates/PODetails/constants";
import { combineMilestoneComponentsState, getCurrentLinItemMilestones } from "../../shared/helpers/milestone.helper";
import {
  IComponentViewData,
  IPoReportRequest,
  IPOUsersOptions,
  IMilestoneProcessViewData,
  IDailyReportRequest,
  IPOCommentsExportRequest,
  ICommentViewData,
  IImageGallery,
} from "../../interfaces/PODetails.interface";
import { getUserName } from "../../templates/ItemsList/helpers";

interface IPOState {
  selectedPO: IPO | null;
  lineItems: ILineItemsState;
  commentLineItems: ILineItemCommentState;
  summary: IPOSummary;
  commentStats: ICommentStats;
  milestoneDetails: {
    milestone: IMilestone | null;
    components: ComponentsState | null;
    comments: IMilestoneComment[];
    selectedComponent: IComponent | null;
    selectedComponentsTab: keyof typeof EDIT_COMPO_TABS;
  };
  selectedListingView: DropdownOptionType | null;
  poUsers: null | IPOUsersOptions;
  poReportUrl: string;
  componentViewData: IComponentViewData;
  commentViewData: ICommentViewData;
  processView: {
    data: IMilestoneProcessViewData[];
    selectedMilestone: string;
  };
  milestoneView: {
    selectedLineItem: null | ILineItemWithMilestones;
  };
  imageGallery: IImageGallery;
}

const initialState: IPOState = {
  selectedPO: null,
  lineItems: [],
  commentLineItems: [],
  summary: {
    totalLineItems: 0,
    completedLineItems: 0,
    delayedLineItems: 0,
    onTrackLineItems: 0,
  },
  commentStats: {
    solved: 0,
    unsolved: 0,
    critical: 0
  },
  milestoneDetails: {
    // current editing milestone details
    milestone: null,
    components: null,
    comments: [],
    selectedComponent: null,
    selectedComponentsTab: "inProgress",
  },
  selectedListingView: null,
  poUsers: null,
  poReportUrl: "",
  componentViewData: [],
  commentViewData: [],
  processView: {
    data: [],
    selectedMilestone: "",
  },
  milestoneView: {
    selectedLineItem: null,
  },
  imageGallery: { commentsWithImages: [], imagesCount: { total: 0 } },
};

export const getPODetails = createAsyncThunk(
  getActionNameForLoader("po/getPODetails"),
  async (poNumber: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await httpService.get(`${ApiConfig.endpoints.get_po_details}/${poNumber}`);
      return getApiRespObj(response.data)?.data;
    } catch (error: any) {
      dispatch(setErrorToast({ message: error?.response?.data?.message }));
      return rejectWithValue(getApiRespObj(error?.response?.data));
    }
  }
);

export const getPOItemsMilstones = createAsyncThunk(
  getActionNameForLoader("po/getPOItemsMilstones"),
  async (paylaod: { poId: string; view: PO_LISTING_VIEW_OPTIONS_VALUE }, { rejectWithValue, dispatch }) => {
    try {
      const { poId, view } = paylaod;
      if (poId && view) {
        const response = await httpService.get(`${ApiConfig.endpoints.get_po_line_items_milstones}/${poId}/${view}`);
        return getApiRespObj(response.data);
      }
    } catch (error: any) {
      dispatch(setErrorToast({ message: error?.response?.data?.message }));
      return rejectWithValue(getApiRespObj(error?.response?.data));
    }
  }
);

export const getResponsiblePersons = createAsyncThunk(
  getActionNameForLoader("po/getResponsiblePersonList"),
  async (projectId: string | undefined, { rejectWithValue, dispatch }) => {
    try {
      const response = await httpService.get(`${ApiConfig.endpoints.get_responsible_person_list}/${projectId}`);
      return getApiRespObj(response.data);
    } catch (error: any) {
      dispatch(setErrorToast({ message: error?.response?.data?.message }));
      return rejectWithValue(getApiRespObj(error?.response?.data));
    }
  }
);

/** Line items **/
export const addLineItem = createAsyncThunk(
  getActionNameForLoader("po/addLineItem"),
  async (lineItem: ICreateLineItem, { rejectWithValue, dispatch }) => {
    try {
      const response = await httpService.post(ApiConfig.endpoints.add_line_item, lineItem);
      return response.data;
    } catch (error: any) {
      dispatch(setErrorToast({ message: error?.response?.data?.message }));
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const editLineItem = createAsyncThunk(
  getActionNameForLoader("po/editLineItem"),
  async (lineItem: Partial<ILineItem>, { rejectWithValue, dispatch }) => {
    try {
      const response = await httpService.patch(ApiConfig.endpoints.edit_line_item, lineItem);
      return getApiRespObj(response.data);
    } catch (error: any) {
      dispatch(setErrorToast({ message: error?.response?.data?.message }));
      return rejectWithValue(getApiRespObj(error?.response?.data));
    }
  }
);

export const deleteLineItem = createAsyncThunk(
  getActionNameForLoader("po/deleteLineItem"),
  async (lineItem: ILineItem, { rejectWithValue, dispatch }) => {
    try {
      const response = await httpService.delete(`${ApiConfig.endpoints.delete_line_item}/${lineItem._id}`);
      return getApiRespObj(response.data);
    } catch (error: any) {
      dispatch(setErrorToast({ message: error?.response?.data?.message }));
      return rejectWithValue(getApiRespObj(error?.response?.data));
    }
  }
);

export const getPOReport = createAsyncThunk(
  getActionNameForLoader("po/getPOReport"),
  async (reportPayload: IPoReportRequest, { rejectWithValue, dispatch }) => {
    try {
      const response = await httpService.post(ApiConfig.endpoints.get_po_report, reportPayload);
      return response.data;
    } catch (error: any) {
      dispatch(setErrorToast({ message: error?.response?.data?.message }));
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getPOComponentsReport = createAsyncThunk(
  getActionNameForLoader("po/getPOComponentsReport"),
  async (reportPayload: IPoReportRequest, { rejectWithValue, dispatch }) => {
    try {
      const response = await httpService.post(ApiConfig.endpoints.get_po_components_report, reportPayload);
      return response.data;
    } catch (error: any) {
      dispatch(setErrorToast({ message: error?.response?.data?.message }));
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getPOWeightedReport = createAsyncThunk(
  getActionNameForLoader("po/getPOWeightedReport"),
  async (reportPayload: IPoReportRequest, { rejectWithValue, dispatch }) => {
    try {
      const response = await httpService.post(ApiConfig.endpoints.get_po_weighted_report, reportPayload);
      return response.data;
    } catch (error: any) {
      dispatch(setErrorToast({ message: error?.response?.data?.message }));
      return rejectWithValue(error?.response?.data);
    }
  }
);

/** Milestone **/
export const addMilestone = createAsyncThunk(
  getActionNameForLoader("po/addMilestone"),
  async (milestone: ICreateMilestone, { rejectWithValue, dispatch }) => {
    try {
      const response = await httpService.post(ApiConfig.endpoints.add_milestone, milestone);
      return response.data;
    } catch (error: any) {
      dispatch(setErrorToast({ message: error?.response?.data?.message }));
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateMilestone = createAsyncThunk(
  getActionNameForLoader("po/updateMilestone"),
  async (milestone: Partial<IUpdateMilestone>, { rejectWithValue, dispatch }) => {
    try {
      const response = await httpService.patch(ApiConfig.endpoints.update_milestone, milestone);
      return getApiRespObj(response.data);
    } catch (error: any) {
      dispatch(setErrorToast({ message: error?.response?.data?.message }));
      return rejectWithValue(getApiRespObj(error?.response?.data));
    }
  }
);

export const createMapping = createAsyncThunk(
  getActionNameForLoader("po/createMapping"),
  async (mappings: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await httpService.post(ApiConfig.endpoints.create_mappings, mappings);
      return getApiRespObj(response.data);
    } catch (error: any) {
      dispatch(setErrorToast({ message: error?.response?.data?.message }));
      return rejectWithValue(getApiRespObj(error?.response?.data));
    }
  }
);

export const checkMapping = createAsyncThunk(
  getActionNameForLoader("po/checkMapping"),
  async (itemCode: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await httpService.get(`${ApiConfig.endpoints.check_mapping}/${itemCode}`);
      return getApiRespObj(response.data);
    } catch (error: any) {
      dispatch(setErrorToast({ message: error?.response?.data?.message }));
      return rejectWithValue(getApiRespObj(error?.response?.data));
    }
  }
);

export const deleteMilestone = createAsyncThunk(
  getActionNameForLoader("po/deleteMilestone"),
  async (milestone: IMilestone, { rejectWithValue, dispatch }) => {
    try {
      const response = await httpService.delete(`${ApiConfig.endpoints.delete_milestone}/${milestone._id}`);
      return getApiRespObj(response.data);
    } catch (error: any) {
      dispatch(setErrorToast({ message: error?.response?.data?.message }));
      return rejectWithValue(getApiRespObj(error?.response?.data));
    }
  }
);

export const uploadMilCommentFile = createAsyncThunk(
  getActionNameForLoader("po/uploadMilCommentFile"),
  async (file: File, { rejectWithValue, dispatch }) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = await httpService.post(ApiConfig.endpoints.upload_mil_comment_file, formData);
      return response.data;
    } catch (error: any) {
      dispatch(setErrorToast({ message: error?.response?.data?.message }));
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchPOUsers = createAsyncThunk(
  "po/fetchPOUsers",
  async (poNumber: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await httpService.get(`${ApiConfig.endpoints.get_po_users}/${poNumber}`);
      return response.data;
    } catch (error: any) {
      dispatch(setErrorToast({ message: error?.response?.data?.message }));
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchMilestoneComments = createAsyncThunk(
  getActionNameForLoader("po/fetchMilestoneComments"),
  async (milestoneId: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await httpService.get(`${ApiConfig.endpoints.get_milestone_comments}/${milestoneId}`);
      return response.data;
    } catch (error: any) {
      dispatch(setErrorToast({ message: error?.response?.data?.message }));
      return rejectWithValue(error?.response?.data);
    }
  }
);

/***** Components ****/
export const getMilestoneComponents = createAsyncThunk(
  getActionNameForLoader("po/getMilestoneComponents"),
  async (milestoneId: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await httpService.get(`${ApiConfig.endpoints.get_milestone_components}/${milestoneId}`);
      return response.data;
    } catch (error: any) {
      dispatch(setErrorToast({ message: error?.response?.data?.message }));
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateComponent = createAsyncThunk(
  getActionNameForLoader("po/updateComponent"),
  async (payload: UpdateMilestoneComponentPayload, { rejectWithValue, dispatch }) => {
    try {
      const response = await httpService.patch(ApiConfig.endpoints.update_milestone_component, payload);
      return getApiRespObj(response.data);
    } catch (error: any) {
      dispatch(setErrorToast({ message: error?.response?.data?.message }));
      return rejectWithValue(getApiRespObj(error?.response?.data));
    }
  }
);

export const moveMultipleComponentsStage = createAsyncThunk(
  getActionNameForLoader("po/moveMultipleComponents"),
  async (payload: UpdateMilestoneComponentsMoveStagePayload, { rejectWithValue, dispatch }) => {
    try {
      const response = await httpService.patch(ApiConfig.endpoints.update_multiple_component_stage, payload);
      return getApiRespObj(response.data);
    } catch (error: any) {
      dispatch(setErrorToast({ message: error?.response?.data?.message }));
      return rejectWithValue(getApiRespObj(error?.response?.data));
    }
  }
);

export const addMilestoneComponentComment = createAsyncThunk(
  getActionNameForLoader("po/addMilestoneComponentComment"),
  async (payload: AddMilestoneComponentCommentPayload, { rejectWithValue, dispatch }) => {
    try {
      const response = await httpService.post(ApiConfig.endpoints.add_component_milestone_comment, payload);
      return getApiRespObj(response.data);
    } catch (error: any) {
      dispatch(setErrorToast({ message: error?.response?.data?.message }));
      return rejectWithValue(getApiRespObj(error?.response?.data));
    }
  }
);

export const addComponent = createAsyncThunk(
  getActionNameForLoader("po/addComponent"),
  async (component: ICreateComponent, { rejectWithValue, dispatch }) => {
    try {
      const response = await httpService.post(ApiConfig.endpoints.add_component, component);
      return getApiRespObj(response.data);
    } catch (error: any) {
      dispatch(setErrorToast({ message: error?.response?.data?.message }));
      return rejectWithValue(getApiRespObj(error?.response?.data));
    }
  }
);

export const updateComponentDetails = createAsyncThunk(
  getActionNameForLoader("po/updateComponentDetails"),
  async (component: Partial<IComponent>, { rejectWithValue, dispatch }) => {
    try {
      const response = await httpService.patch(`${ApiConfig.endpoints.update_component}/${component._id}`, component);
      return getApiRespObj(response.data);
    } catch (error: any) {
      dispatch(setErrorToast({ message: error?.response?.data?.message }));
      return rejectWithValue(getApiRespObj(error?.response?.data));
    }
  }
);

export const deleteComponent = createAsyncThunk(
  getActionNameForLoader("po/deleteComponent"),
  async (componentId: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await httpService.delete(`${ApiConfig.endpoints.delete_component}/${componentId}`);
      return getApiRespObj(response.data);
    } catch (error: any) {
      dispatch(setErrorToast({ message: error?.response?.data?.message }));
      return rejectWithValue(getApiRespObj(error?.response?.data));
    }
  }
);

export const fetchComponentSubLevelComponents = createAsyncThunk(
  getActionNameForLoader("po/fetchComponentSubLevelComponents"),
  async (componentId: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await httpService.get(`${ApiConfig.endpoints.get_sub_level_components}/${componentId}`);
      return response.data;
    } catch (error: any) {
      dispatch(setErrorToast({ message: error?.response?.data?.message }));
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateMultipleComponentDetails = createAsyncThunk(
  getActionNameForLoader("po/updateMultipleComponentDetails"),
  async (components: Partial<IComponent>[], { rejectWithValue, dispatch }) => {
    try {
      const response = await httpService.patch(`${ApiConfig.endpoints.update_multiple_components}`, components);
      return getApiRespObj(response.data);
    } catch (error: any) {
      dispatch(setErrorToast({ message: error?.response?.data?.message }));
      return rejectWithValue(getApiRespObj(error?.response?.data));
    }
  }
);

export const getDailyReport = createAsyncThunk(
  getActionNameForLoader("po/getDailyReport"),
  async (reportPayload: IDailyReportRequest, { rejectWithValue, dispatch }) => {
    try {
      const response = await httpService.post(ApiConfig.endpoints.get_daily_report, reportPayload);
      return response.data;
    } catch (error: any) {
      dispatch(setErrorToast({ message: error?.response?.data?.message }));
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const resolveComment = createAsyncThunk(
  getActionNameForLoader("po/resolveComment"),
  async (payload: { commentId: string, resolutionComment: string }, { rejectWithValue, dispatch }) => {
    try {
      const { commentId, resolutionComment } = payload;
      const response = await httpService.patch(`${ApiConfig.endpoints.resolve_comment}/${commentId}/${resolutionComment}`);
      return getApiRespObj(response.data);
    } catch (error: any) {
      dispatch(setErrorToast({ message: error?.response?.data?.message }));
      return rejectWithValue(getApiRespObj(error?.response?.data));
    }
  }
);

export const remindUser = createAsyncThunk(
  getActionNameForLoader("po/remindUser"),
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await httpService.patch(`${ApiConfig.endpoints.remind_user}`, payload);
      return getApiRespObj(response.data);
    } catch (error: any) {
      dispatch(setErrorToast({ message: error?.response?.data?.message }));
      return rejectWithValue(getApiRespObj(error?.response?.data));
    }
  }
);


export const exportPOComments = createAsyncThunk(
  getActionNameForLoader("po/exportPOComments"),
  async (payload: IPOCommentsExportRequest, { rejectWithValue, dispatch }) => {
    try {
      const response = await httpService.post(`${ApiConfig.endpoints.export_po_comments}`, payload);
      return response.data;
    } catch (error: any) {
      dispatch(setErrorToast({ message: error?.response?.data?.message }));
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getPOCommentsImages = createAsyncThunk(
  getActionNameForLoader("po/getPOCommentsImages"),
  async (poId: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await httpService.get(`${ApiConfig.endpoints.get_po_comments_images}/${poId}`);
      return response.data;
    } catch (error: any) {
      dispatch(setErrorToast({ message: error?.response?.data?.message }));
      return rejectWithValue(error?.response?.data);
    }
  }
);

const POSlice = createSlice({
  name: "po",
  initialState,
  reducers: {
    setSelectedPO(state, action) {
      state.selectedPO = action.payload;
    },
    setSelectedMilestone(state, action) {
      state.milestoneDetails.milestone = action.payload;
    },
    resetMilestoneDetails(state) {
      state.milestoneDetails = initialState.milestoneDetails;
    },
    setSelectedListingView(state, action) {
      state.selectedListingView = action.payload;
    },
    resetPODetails(state) {
      Object.assign(state, initialState);
    },
    resetPoReportUrl(state) {
      state.poReportUrl = "";
    },
    setSelectedMilestoneProcessView(state, action) {
      state.processView.selectedMilestone = action.payload;
    },
    setSelectedComponentEditMilestone(state, action) {
      state.milestoneDetails.selectedComponent = action.payload;
    },
    setSelectedLineItemMilestoneView(state, action) {
      state.milestoneView.selectedLineItem = action.payload;
    },
    setSelectedEditMileComposTab(state, action) {
      state.milestoneDetails.selectedComponentsTab = action.payload;
    },
  },
  extraReducers: (builder) => {
    // upon logout
    builder.addCase(PURGE, () => initialState);

    // Add extra reducers here
    builder.addCase(getPOItemsMilstones.fulfilled, (state, action) => {
      switch (state?.selectedListingView?.value) {
        case PO_LISTING_VIEW_OPTIONS_VALUE.MILESTONE:
          const list = action.payload?.data?.list;
          state.lineItems = list;
          state.summary = action.payload?.data?.summary?.[0];
          state.commentStats = action.payload?.data?.commentStats;
          if (state.milestoneView?.selectedLineItem?.lineItem) {
            const updatedLineItem = list.find(
              (item: ILineItemWithMilestones) =>
                item.lineItem._id === state.milestoneView?.selectedLineItem?.lineItem._id
            );
            state.milestoneView.selectedLineItem = updatedLineItem
              ? { lineItem: updatedLineItem.lineItem, milestones: updatedLineItem.milestones }
              : null;
          }
          break;
        case PO_LISTING_VIEW_OPTIONS_VALUE.COMPONENT:
          state.componentViewData = action.payload?.data;
          break;
        case PO_LISTING_VIEW_OPTIONS_VALUE.PROCESS:
          state.processView = { ...state.processView, data: action.payload?.data?.processViewData };
          state.lineItems = action.payload?.data?.list;
          break;
        case PO_LISTING_VIEW_OPTIONS_VALUE.COMMENT:
          state.commentViewData = action.payload?.data?.result;
          state.commentLineItems = action.payload?.data?.result;
          state.commentStats = action.payload?.data?.commentStats;

          break;
        default:
          break;
      }
      // Update the milestone details if the milestone is already selected to edit
      if (state.milestoneDetails.milestone) {
        const milestones = getCurrentLinItemMilestones(
          state.milestoneDetails.milestone.itemId as ILineItem,
          state.lineItems
        );
        const currentMilestone = milestones.find(
          (milestone: any) => milestone._id === state.milestoneDetails.milestone?._id
        );
        state.milestoneDetails.milestone = currentMilestone || null;
      }
    });

    builder.addCase(getMilestoneComponents.fulfilled, (state, action) => {
      const components = action?.payload || [];
      state.milestoneDetails = { ...state.milestoneDetails, components };
      const combinedComponents = combineMilestoneComponentsState(components);
      const selectedComponent = state.milestoneDetails.selectedComponent;
      if (selectedComponent) {
        const updatedSelectedComponent = combinedComponents.find((comp) => comp._id === selectedComponent._id);
        if (updatedSelectedComponent) {
          state.milestoneDetails.selectedComponent = updatedSelectedComponent;
        }
      }
    });

    builder.addCase(fetchMilestoneComments.fulfilled, (state, action) => {
      state.milestoneDetails = { ...state.milestoneDetails, comments: action?.payload?.reverse() || [] };
    });

    builder.addCase(addMilestoneComponentComment.fulfilled, (state) => {
      if (state.milestoneDetails.selectedComponent) {
        state.milestoneDetails.selectedComponent = {
          ...state.milestoneDetails.selectedComponent,
          totalComments: (state.milestoneDetails.selectedComponent?.totalComments || 0) + 1,
        };
      }
    });

    builder.addCase(fetchPOUsers.fulfilled, (state, action) => {
      const accountableUsersOptions = action.payload.data?.accountableUsersOptions?.map((user: any) => ({
        label: getUserName(user?.userId),
        value: user?.userId,
      }));
      const responsibleUsersOptions = action.payload.data?.responsibleUsersOptions?.map((user: any) => ({
        label: getUserName(user?.userId) || user?.userId?.companyName,
        value: user?.userId,
      }));
      state.poUsers = { accountablePerson: accountableUsersOptions, responsiblePerson: responsibleUsersOptions };
    });

    builder.addCase(getPOReport.fulfilled, (state, action) => {
      state.poReportUrl = action?.payload;
    });

    builder.addCase(getPOComponentsReport.fulfilled, (state, action) => {
      state.poReportUrl = action?.payload;
    });

    builder.addCase(getPOWeightedReport.fulfilled, (state, action) => {
      state.poReportUrl = action?.payload;
    });

    builder.addCase(getDailyReport.fulfilled, (state, action) => {
      state.poReportUrl = action?.payload;
    });

    builder.addCase(getPODetails.fulfilled, (state, action) => {
      if (state.selectedPO && action.payload) {
        state.selectedPO = { ...state.selectedPO, ...action.payload };
      }
    });

    builder.addCase(resolveComment.fulfilled, (state, action) => {
      state.milestoneDetails.comments = state.milestoneDetails.comments.map((comment) =>
        comment._id === action.payload.data._id ? action.payload.data : comment
      );
    });

    builder.addCase(exportPOComments.fulfilled, (state, action) => {
      state.poReportUrl = action?.payload;
    });

    builder.addCase(getPOCommentsImages.fulfilled, (state, action) => {
      state.imageGallery = action.payload.data;
    });
  },
});

export const {
  setSelectedPO,
  setSelectedMilestone,
  resetMilestoneDetails,
  setSelectedListingView,
  resetPODetails,
  resetPoReportUrl,
  setSelectedMilestoneProcessView,
  setSelectedComponentEditMilestone,
  setSelectedLineItemMilestoneView,
  setSelectedEditMileComposTab,
} = POSlice.actions;

export default POSlice.reducer;

export const usePOSelector = () => useSelector((state: RootState): IPOState => state.po);
