import React, { useEffect } from "react";
import "./App.css";
import routes from "./routes/Routes.tsx";
import { createGlobalStyle } from "styled-components";
import { globalStyles } from "./Theme";
import { useAuthenticationSelector, setUserCategory } from "./store/slices/AuthenticationSlice";
import { useAppDispatch } from "./store/hooks";
import { AppConstants } from "./constants/AppConstants";
import { LocalStorage } from "./services";
import { resetLoader } from "./store/slices/LoaderSlice";
import { setVh } from "./shared/helpers/app.helper";
import { CLASS_NAMES } from "./constants/CSS.const";

export const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box; 
  }
  
  html {
    font-size:${(props) => props.theme.baseFontSize};
  }
  body {
    font-family:${(props) => props.theme.fontFamily};
    background-color:${(props) => props.theme.bodyBG};
    color: ${(props) => props.theme.baseFontColor};
    button, textarea, input {
      font-family:${(props) => props.theme.fontFamily};
    }
    .pointer-cursor {
      cursor: pointer;
      pointer-events: auto;
    }
    .pointer-cursor > * {
      cursor: pointer;
      pointer-events: auto;
    }

    .${CLASS_NAMES.HIDE_SCROLLBAR}{
      /* Hide scrollbar for Chrome, Safari and Opera */
      ::-webkit-scrollbar {
        display: none;
      }

      /* Hide scrollbar for IE, Edge and Firefox */
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    /* -moz-appearance: textfield; */
  }
  

  ::-webkit-scrollbar {
    width: 8px; /* width of the entire scrollbar */
  }

  ::-webkit-scrollbar-track {
    background: transparent; /* color of the tracking area */
  }

  ::-webkit-scrollbar-thumb {
    width: 8px;
    height: 30%;
    background: #d5d9ec;
    border-radius: 10px; /* color of the scroll thumb */
    /* roundness of the scroll thumb */
    /* creates padding around scroll thumb */
    padding-bottom:60px;
  }
`;

function App() {
  const { userCategory } = useAuthenticationSelector();
  const dispatch = useAppDispatch();
  const { STORAGE_KEYS } = AppConstants;

  useEffect(() => {
    dispatch(resetLoader());
    // Set the height on load
    setVh();
    // Update the height on resize
    window.addEventListener("orientationchange", setVh);
    if (window.visualViewport) {
      window.visualViewport.addEventListener("resize", setVh);
    } else {
      window.addEventListener("resize", setVh);
    }
    return () => {
      window.removeEventListener("resize", setVh);
      window.removeEventListener("orientationchange", setVh);
      dispatch(resetLoader());
    };
  }, []);

  useEffect(() => {
    if (!userCategory) {
      const rememberedAs = LocalStorage.get(STORAGE_KEYS.userCategory);
      dispatch(setUserCategory(rememberedAs));
    }
  }, [userCategory]);

  return (
    <div className="App">
      <GlobalStyle theme={globalStyles} />
      {routes}
    </div>
  );
}

export default App;
